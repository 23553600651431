export const initMailchimp = () => {


  const form = document.getElementById('mc-embedded-subscribe-form');
  const requiredInputs = form.querySelectorAll('.RS-required');
  const submitInput = form.querySelector('[type="submit"]');
  // const submitButtonArrow = form.querySelector('.RS-button__arrow');
  const submitButtonLoader = form.querySelector('.RS-button__loader');


  window.addEventListener("pageshow", () => {
      submitInput.disabled = false;
      submitInput.classList.remove('RS-submit--is-hidden');
      // submitButtonArrow.classList.remove('RS-button__arrow--is-hidden');
      submitButtonLoader.classList.remove('RS-button__loader--is-running');
  });


  form.addEventListener('submit', function (e) {
    e.preventDefault();
    let hasError = false;

    requiredInputs.forEach(input => {
      if (input.type === 'text' || input.type === 'email') {
        if (!input.value.trim()) {
          showError(input, 'To pole jest wymagane');
          hasError = true;
        } else if (input.type === 'email' && !validateEmail(input.value.trim())) {
          showError(input, 'Niepoprawny format email');
          hasError = true;
        } else {
          hideError(input);
        }
      }
      //  else if (input.type === 'radio') {
      //     const checkedRadios = form.querySelectorAll(`input[type="radio"]:checked`);

      //     if (checkedRadios.length !== 2) {
      //         showError(input, 'Zaznaczenie zgód jest wymagane');
      //         hasErrors = true;
      //     }else{
      //         hideError(input);
      //         hasErrors = false;
      //     }
      // }
    });

    if (!hasError) {
      // const checkedRadios = form.querySelectorAll(`input[type="radio"]:checked`);
      // if(checkedRadios.length === 2){
          form.submit();
          submitInput.disabled = true;
          submitInput.classList.add('RS-submit--is-hidden');
          // submitButtonArrow.classList.add('RS-button__arrow--is-hidden');
          submitButtonLoader.classList.add('RS-button__loader--is-running');
      // } 
    }
  });

  

  const textEmailInputs = form.querySelectorAll("input[type='text'], input[type='email']");
  textEmailInputs.forEach(function (input) {
      input.addEventListener("blur", function () {
          if (input.value.trim() === "") {
              showError(input, 'To pole jest wymagane');
          } else if (input.type === 'email' && !validateEmail(input.value.trim())) {
              showError(input, 'Niepoprawny format email');
          } else {
              hideError(input);
          }
      });
      input.addEventListener("keyup", function () {
          if (input.value.trim() === "") {
              showError(input, 'To pole jest wymagane');
          } else if (input.type === 'email' && !validateEmail(input.value.trim())) {
              // showError(input, 'Niepoprawny format email');
          } else {
              hideError(input);
          }
      });
  });


  // const radioInputLabels = form.querySelectorAll("input[type='radio'] + label");
  // radioInputLabels.forEach(function (label) {
  //     const input = label.previousElementSibling;
  //     input.addEventListener("change", function () {
  //     const checkedRadios = form.querySelectorAll(`input[type="radio"]:checked`);
  //         if(checkedRadios.length === 2){
  //             hideError(label);
  //         } 
  //     });
  // });


  function showError(input, message) {
      const errorSpan = input.closest('.RS-mailchimp__field-group').querySelector('.RS-error');
      if (errorSpan) {
          errorSpan.classList.add('RS-error--is-visible');
          errorSpan.textContent = message;
      }
  }
  
  function hideError(input) {
      const errorSpan = input.closest('.RS-mailchimp__field-group').querySelector('.RS-error');
      if (errorSpan) {
          errorSpan.classList.remove('RS-error--is-visible');
      }
  }

  function validateEmail(email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
  }

};